import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"


const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const { logout, isAuthenticated } = useAuth0()
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/pages/account-settings")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>

      <DropdownItem divider />

      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={e => {
          e.preventDefault()
          if (isAuthenticated) {
            return logout({
              returnTo: window.location.origin + process.env.REACT_APP_PUBLIC_PATH
            })
          } else {
            const provider = props.loggedInWith
            if (provider !== null) {
              if (provider === "jwt") {
                return props.logoutWithJWT()
              }
              if (provider === "firebase") {
                return props.logoutWithFirebase()
              }
            } else {
              try {
                var userId= sessionStorage.getItem("userId");
                sessionStorage.removeItem("userId");
                  sessionStorage.setItem("userId", "");
                  sessionStorage.setItem("loggedAsli", "");
                  sessionStorage.setItem("instituteId", "");
                  sessionStorage.setItem("name", "");
                  sessionStorage.setItem("photoUrl", "");
                  sessionStorage.setItem("email", "");
                  sessionStorage.setItem("clientTimeZone", "");
                var formData = new FormData();
                formData.append("userId", sessionStorage.getItem("userId"));

                axios.post(window.$API_URL + "User/studentSessionDestroye", formData).then(res => {


                  

                  // window.location.href = "/";

                });
                


              } catch (error) {
                // Error saving data
              }
              history.push("/pages/login")
            }
          }

        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

var userId = "0", instituteId = "0";

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    profileData: [],
    photoUrl: '',
    shoppingCart: [
      // {
      //   id: 1,
      //   name:
      //     "Apple - Apple Watch Series 1 42mm Space Gray Aluminum Case Black Sport Band - Space Gray Aluminum",
      //   desc:
      //     "Durable, lightweight aluminum cases in silver, space gray, gold, and rose gold. Sport Band in a variety of colors. All the features of the original Apple Watch, plus a new dual-core processor for faster performance. All models run watchOS 3. Requires an iPhone 5 or later.",
      //   price: "$299",
      //   img: require("../../../assets/img/pages/eCommerce/4.png"),
      //   width: 75
      // },
      // {
      //   id: 2,
      //   name:
      //     "Apple - Macbook® (Latest Model) - 12' Display - Intel Core M5 - 8GB Memory - 512GB Flash Storage Space Gray",
      //   desc:
      //     "MacBook delivers a full-size experience in the lightest and most compact Mac notebook ever. With a full-size keyboard, force-sensing trackpad, 12-inch Retina display,1 sixth-generation Intel Core M processor, multifunctional USB-C port, and now up to 10 hours of battery life,2 MacBook features big thinking in an impossibly compact form.",
      //   price: "$1599.99",
      //   img: require("../../../assets/img/pages/eCommerce/dell-inspirion.jpg"),
      //   width: 100,
      //   imgClass: "mt-1 pl-50"
      // },
      // {
      //   id: 3,
      //   name: "Sony - PlayStation 4 Pro Console",
      //   desc:
      //     "PS4 Pro Dynamic 4K Gaming & 4K Entertainment* PS4 Pro gets you closer to your game. Heighten your experiences. Enrich your adventures. Let the super-charged PS4 Pro lead the way.** GREATNESS AWAITS",
      //   price: "$399.99",
      //   img: require("../../../assets/img/pages/eCommerce/7.png"),
      //   width: 88
      // },
      // {
      //   id: 4,
      //   name:
      //     "Beats by Dr. Dre - Geek Squad Certified Refurbished Beats Studio Wireless On-Ear Headphones - Red",
      //   desc:
      //     "Rock out to your favorite songs with these Beats by Dr. Dre Beats Studio Wireless GS-MH8K2AM/A headphones that feature a Beats Acoustic Engine and DSP software for enhanced clarity. ANC (Adaptive Noise Cancellation) allows you to focus on your tunes.",
      //   price: "$379.99",
      //   img: require("../../../assets/img/pages/eCommerce/10.png"),
      //   width: 75
      // },
      // {
      //   id: 5,
      //   name:
      //     "Sony - 75' Class (74.5' diag) - LED - 2160p - Smart - 3D - 4K Ultra HD TV with High Dynamic Range - Black",
      //   desc:
      //     "This Sony 4K HDR TV boasts 4K technology for vibrant hues. Its X940D series features a bold 75-inch screen and slim design. Wires remain hidden, and the unit is easily wall mounted. This television has a 4K Processor X1 and 4K X-Reality PRO for crisp video. This Sony 4K HDR TV is easy to control via voice commands.",
      //   price: "$4499.99",
      //   img: require("../../../assets/img/pages/eCommerce/sony-75class-tv.jpg"),
      //   width: 100,
      //   imgClass: "mt-1 pl-50"
      // },
      // {
      //   id: 6,
      //   name:
      //     "Nikon - D810 DSLR Camera with AF-S NIKKOR 24-120mm f/4G ED VR Zoom Lens - Black",
      //   desc:
      //     "Shoot arresting photos and 1080p high-definition videos with this Nikon D810 DSLR camera, which features a 36.3-megapixel CMOS sensor and a powerful EXPEED 4 processor for clear, detailed images. The AF-S NIKKOR 24-120mm lens offers shooting versatility. Memory card sold separately.",
      //   price: "$4099.99",
      //   img: require("../../../assets/img/pages/eCommerce/canon-camera.jpg"),
      //   width: 70,
      //   imgClass: "mt-1 pl-50"
      // }
    ],
    suggestions: [],
    userdata: sessionStorage.getItem("studentData"),
    instituteExpire: sessionStorage.getItem("instituteExpire"),
    userExpire: sessionStorage.getItem("userExpire"),
    days: parseInt(sessionStorage.getItem("days")),
    notificationList: [],
    userName: '',
    usersList: [],
    userImg: ''
  }

  getUsers() {
    var currentComponent = this;
    // axios.post(window.$API_URL + "User/getUsers").then(res => {
      
    //   currentComponent.setState({
    //     usersList: res.data.users

    //   })
    //   var usersData = res.data.users.filter(item => item.userId == sessionStorage.getItem("userId"))
      
    //   if (usersData.length > 0) {
        
    //     currentComponent.setState({
    //       userName: usersData[0].name,
    //       userImg: usersData[0].profilePic

    //     })
    //     if (usersData[0].profilePic != "" && usersData[0].profilePic != null && usersData[0].profilePic != undefined) {
    //       currentComponent.setState({
    //         userImg: usersData[0].profilePic
    //       })
    //     }
    //     else {
    //       currentComponent.setState({
    //         userImg: "https://lms.aslimaster.com/uploads/no_image.png"
    //       })
    //     }
    //   }

    // }, error => {

    //   // handle the error
    //   // toast.error("Server Error Try Again", {
    //   //   position: "top-right"
    //   // });
    // });
  }
  async componentDidMount() {

    var value = await sessionStorage.getItem('userId');
    if (value !== null && value != undefined && value != "") {
      userId = value;
      this.getUsers();
    }
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
    this.checkexpirystorage = this.checkexpirystorage.bind(this);
    this.checkexpirystorage();
    setInterval(this.checkexpirystorage(), 1500);
    this.loadNotifications();
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })
  checkexpirystorage() {
    this.setState({ userExpire: sessionStorage.getItem("userExpire") });
    this.setState({ instituteExpire: sessionStorage.getItem("instituteExpire") });
    this.setState({ days: parseInt(sessionStorage.getItem("days")) });
  }
  onlogoutClick() {

    // userId=sessionStorage.getItem("userId");
    var formData = new FormData();
    formData.append("userId", sessionStorage.getItem("userId"));

    axios.post(window.$API_URL + "User/studentSessionDestroye", formData).then(async (res) => {


      try {

        await sessionStorage.setItem('userId', "");
        await sessionStorage.setItem('instituteId', "");
        await sessionStorage.setItem('name', "");
        await sessionStorage.setItem('photoUrl', "");
        await sessionStorage.setItem('email', "");
        await sessionStorage.setItem('clientTimeZone', "");
        window.location.href = "/";
        // return true;
      }
      catch (exception) {
        // return false;
      }



    });


  }
  readAllNotification(e) {
    // userId=sessionStorage.getItem("userId");
    // instituteId=sessionStorage.getItem("instituteId");
    var formData = new FormData();
    formData.append("userId", sessionStorage.getItem("userId"));
    // formData.append("instituteId", instituteId);
    //instituteId
    axios.post(window.$API_URL + "Notification/readAll", formData).then(res => {
      // console.log(res)
      // console.log(sessionStorage.getItem("userId"))
      this.loadNotifications();
      

    });
  }
  loadNotifications() {
    // userId=sessionStorage.getItem("userId");
    // instituteId=sessionStorage.getItem("instituteId");
    var formData = new FormData();
    formData.append("userId", sessionStorage.getItem("userId"));
    formData.append("instituteId", instituteId);
    //instituteId
    axios.post(window.$API_URL + "Notification/notificationsHome", formData).then(res => {

      
      this.setState({
        notificationList: res.data
      })
      // sessionStorage.setItem("loggedAsli", "");
      // sessionStorage.removeItem("loggedAsli");
      // sessionStorage.removeItem("userId");
      // sessionStorage.removeItem("instituteId");
      // sessionStorage.removeItem("name");
      // sessionStorage.removeItem("photoUrl");
      // sessionStorage.removeItem("email");

      // window.location.href = "/";

    });
  }
  render() {
    const renderCartItems = this.state.shoppingCart.map(item => {
      return (
        <div className="cart-item" key={item.id}>
          <Media
            className="p-0"
            onClick={() => history.push("/ecommerce/product-detail")}
          >
            <Media className="text-center pr-0 mr-1" left>
              <img
                className={`${item.imgClass
                  ? item.imgClass + " cart-item-img"
                  : "cart-item-img"
                  }`}
                src={item.img}
                width={item.width}
                alt="Cart Item"
              />
            </Media>
            <Media className="overflow-hidden pr-1 py-1 pl-0" body>
              <span className="item-title text-truncate text-bold-500 d-block mb-50">
                {item.name}
              </span>
              <span className="item-desc font-small-2 text-truncate d-block">
                {item.desc}
              </span>
              <div className="d-flex justify-content-between align-items-center mt-1">
                <span className="align-middle d-block">1 x {item.price}</span>
                <Icon.X
                  className="danger"
                  size={15}
                  onClick={e => {
                    e.stopPropagation()
                    this.removeItem(item.id)
                  }}
                />
              </div>
            </Media>
          </Media>
        </div>
      )
    })

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en": "English",
              // "de" : "German",
              // "fr" : "French",
              // "pt" : "Portuguese"
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={this.state.langDropdown}
                // toggle={this.handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode={
                      context.state.locale === "en"
                        ? "us"
                        : context.state.locale
                    }
                    svg
                  />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langArr[context.state.locale]}
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("fr")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="fr" svg />
                    <span className="ml-1">French</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("de")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="de" svg />
                    <span className="ml-1">German</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("pt")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="pt" svg />
                    <span className="ml-1">Portuguese</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer>

        <NavItem hidden className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Explore Vuexy..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={e => {
                this.setState({ navbarSearch: false })
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"]
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                  ? item.email
                                  : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                                ? item.date
                                : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                )
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput)
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation()
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }}
              />
            </div>
          </div>
        </NavItem>


        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle onClick={e => handleNavigation(e, "/expired")} tag="a" className="nav-link position-relative">
            <Icon.ShoppingCart size={21} />
            {/* {this.state.shoppingCart.length > 0 ? (
              <Badge pill color="primary" className="badge-up">
                {this.state.shoppingCart.length}
              </Badge>
            ) : null} */}
          </DropdownToggle>

        </UncontrolledDropdown>


        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {/* {" "} */}
              {this.state.notificationList.length}
              {" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{this.state.notificationList.length} New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {this.state.notificationList.map((item, index) => (
                <div className="d-flex justify-content-between">
                  <Media className="d-flex align-items-start">
                    <Media left href="#">
                      <Icon.PlusSquare
                        className="font-medium-5 primary"
                        size={21}
                      />
                    </Media>
                    <Media body>
                      <Media heading className="primary media-heading" tag="h6">
                        {item.notificationTitle}
                      </Media>
                      {/* <p className="notification-text">
                      Are your going to meet me tonight?
                    </p> */}
                      <small dangerouslySetInnerHTML={{ __html: item.notification }} className="notification-text"></small>
                    </Media>
                    <small>
                      <time
                        className="media-meta"
                        dateTime="2015-06-11T18:29:20+08:00"
                      >
                        {item.createdAt}
                      </time>
                    </small>
                  </Media>
                </div>
              ))}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem onClick={this.readAllNotification.bind(this)} tag="a" className="p-1 text-center">
                <span className="align-middle">Read all notifications</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.state.userName}
              </span>
              <span className="user-status"></span>
            </div>
            <span data-tour="user">
              <img
                src={this.state.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
