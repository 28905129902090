import React, {useEffect} from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"

import backgroundImage1 from '../assets/img/pages/login/images/1.jpg'
import backgroundImage2 from '../assets/img/pages/login/images/2.jpg'
import backgroundImage3 from '../assets/img/pages/login/images/3.jpg'
import backgroundImage4 from '../assets/img/pages/login/images/4.jpg'
import backgroundImage5 from '../assets/img/pages/login/images/5.png'
import backgroundImage6 from '../assets/img/pages/login/images/6.jpg'
import backgroundImage7 from '../assets/img/pages/login/images/7.jpg'
import backgroundImage8 from '../assets/img/pages/login/images/8.jpg'

var bgStyle="";
const FullPageLayout = ({ children, ...rest }) => {

  useEffect(() => {
    
  

    
    const pictureArray = [backgroundImage1, backgroundImage2, backgroundImage3, backgroundImage4, backgroundImage5, backgroundImage6, backgroundImage7, backgroundImage8];
    const randomIndex = Math.floor(Math.random() * pictureArray.length);
    const selectedPicture = pictureArray[randomIndex];
  
      // document.getElementsByClassName("bg-full-screen-image").style.backgroundImage = `url(${selectedPicture})`;
      // document.getElementsByClassName("bg-full-screen-image").style.backgroundRepeat = "no-repeat";
      // document.getElementsByClassName("bg-full-screen-image").style.backgroundSize = "cover";

    // document.body.style.backgroundImage = `url(${selectedPicture})`;
    // document.body.style.height = "100%";
    // document.body.style.backgroundPosition = "fixed";
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundSize = "cover";
    // document.body.style.width = "100%";

    // bgStyle= {
    //       backgroundImage: `url(${selectedPicture})`,
    //       height: "100%",
    //       backgroundPosition: "fixed",
    //       backgroundRepeat: "no-repeat",
    //       backgroundSize: "cover",
    //       left: 0,
    //       top: 0,
    //       width: "100%",
    //       maxHeight: "100%",
    //       border: 5
  
    //     }
  }, [])

  return (
    <div 
      className={classnames(
        " wrapper blank-page dark-layout",
        {
          "layout-dark": themeConfig.layoutDark
        }
      )}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageLayout
